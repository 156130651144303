.custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none; /* Prevent text selection */
  }
  .custom-checkbox input { /* Hide the default checkbox */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {  
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ddd; 
  }
  .custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .custom-checkbox input:checked ~ .checkmark {
    background-color: #6d40f3;
  }
  .checkmark:after { /* The checkmark itself */
    content: "";
    position: absolute;
    display: none; 
  }
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  .custom-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .check-label{
    font-size: .9rem;
    padding-left: 1.8rem;
  }