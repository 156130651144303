.row__style{
    margin-bottom: 20px;
}

.section__title{
    font-weight: 500;
    color: #153E4D;
    margin: 16px 0;
    font-size: .9rem;
}

/* .col__style{
    box-shadow: 0px 0px 15px -10px rgb(5, 68, 104);
} */

.card-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
.card-grid-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}
.card-child{
    width: 100%;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 5px 10px rgb(0, 0, 0, .05);
    height: 7rem;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #153E4D;
    transition: .3s ease-in-out;
    cursor: pointer;
}
.card-title{
    font-size: 2.5rem;
    font-weight: 700;
    color: inherit;
    line-height: 1;
}
.card-sec{
    font-size: .9rem;
    color: inherit;
}
.card-child:nth-child(1){
   border-left: 5px solid #6d40f3;
}
.card-child:nth-child(2){
    border-left: 5px solid #40f379;
}
.card-child:nth-child(3){
    border-left: 5px solid #f34079;
}
.table-head-row{
    background-color: #fcfcfc;
    border: 1px solid;
    border-color: #e2e8f0;
    white-space: nowrap;
}
.tbody-row{
    border-right: 1px solid #e2e8f0;
    border-width: 1px;
}
.table-head{
    font-size: 0.81rem;
    color: rgb(142, 139, 135);
    font-weight: 500;
    height: 3rem;
    padding: 0.75rem 0.5rem !important;
    border-right: 1px solid;
    border-color: #e2e8f0;
    white-space: nowrap;

}
.tbody-data{
    font-size: 0.81rem;
    color: rgb(142, 139, 135);
    font-weight: 400;
    height: 3rem;
    padding: 0.75rem 0.5rem !important;
    border-right: 1px solid;
    border-color: #e2e8f0;
}
.activity{
    width: 100%;
    box-shadow: 0px 5px 10px rgb(0, 0, 0, .05);
border: 1px solid #e2e8f0;
}
.no-activity{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-items: center;
    color: #2b3e45;
    padding: 6.8rem;
}
.no-activity-title{
    font-size: 1rem;
}
.no-activity-icon{
    font-size: 2rem;
    color: rgba(172, 39, 39, 0.846);
}

@media only screen and (max-width: 720px) {
    .card-grid-2,.card-grid{
        grid-template-columns: 1fr;
    }
}