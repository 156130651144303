.main__container{
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.image-container{
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login__container{
    padding: 0 150px;
    width: 50%;
    height: fit-content;
    background: white;
   
}

.login__input{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__input h2 {
    margin-bottom: 30px;
    font-weight: 700;
}

.input__field {
    margin-bottom: 25px;
    height: 3rem;
}

.login__input button{
    width: 100%;
    margin-bottom: 20px;
}

.password{
    font-weight: bolder;
    text-decoration: none;
    color: black;
}
.my-btn{
    background-color: #6d40f3;
    height: 3rem;
    border-radius: 6px;
    border: 1px solid #6d40f3;
    color: white;
}
.my-btn:hover{
    background-color: #492c9e;
}
.my-btn:disabled{
    background-color: #6d40f365;
}

/* -- change form control boostrap style -- */
.form-control:focus{
    border-color: #6d40f3 !important;
    box-shadow: 0 0 0 0.1rem #6d40f3 !important;
  }

@media only screen and (max-width: 720px) {
    .login__container{
        padding: 0 30px;
        width: 100%;
        height: 80vh;
        margin: auto;
    }
    .image-container{
        width: 100%;
        height: 50px;
    }
    .main__container{
        flex-direction: column;
    }
  }