@import url('react-toastify/dist/ReactToastify.css');
@import url('react-loading-skeleton/dist/skeleton.css');
.section__container{
    width: 85%;
    margin-left: auto;
    padding: 20px 3rem;
}

.header__title h3{
    font-weight: 700;
    color: #153E4D;
    margin: 20px 0;
}

.container__wrapper{
    display: flex;
}

.btn__style{
    padding: 3px;
}
tbody{
    position: relative;
}
.data-box{
    position: absolute;
    display: flex;
    width: 100%;
    height: 38vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #20363e;
    border: 1px dashed #e2e8f0;
    margin-top: .5rem;
}
.nodata-text{
    font-size: .9rem;
}
.nodata-icon{
    font-size: 1.6rem;
    color: #6d7376;
}
.people-head{
    min-height: 45vh;
}
.dash-head{
    min-height: 25vh;
}
.dash-head .data-box{
    height: 20vh;
}
.product-top,.product-btm{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: fit-content;
}
.product-btm{
    margin: 1.5rem 0 0;
    padding: 0 6rem;
    width: 95%;
}
.product-customer{
    display: flex;
    gap: 1rem;
    align-items: center;
    height: fit-content;
}
.circle{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #6d40f3;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
}
.p-name{
    font-size: 1.5rem;
    font-weight: 600;
}
.p-sub{
    font-size:.9rem;
    color: #6d7376;
    font-weight: 500;
}
.update-btn{
    background-color: #6d40f3;
    color: white;
    font-weight: 500;
    border: 0;
    height: 3rem;
    padding: 0 1.5rem;
}
.user-sec{
    display: flex;
    flex-direction: column;
    gap:.4rem
}
.user-t{
    font-size: .75rem;
    color: #6d7376;
    font-weight: 500;
}
.user-m{
    font-size: 1rem;
    color: #252729;
    font-weight: 500;
}
.product__detail{
    width: 100%;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .1);
}
.filter-box{
    display: flex;
    gap: .5rem;
    /* margin-bottom: 1rem; */
}
.filt-box{
     padding: 0 1rem;
     background-color: white;
     height: 2.6rem;
     box-shadow: 0 0px 5px rgba(0, 0, 0, .05);
     font-size: .9rem;
     display: flex;
     justify-content: center;
     align-items: center;
     border: .5px solid #d0d0d081;
     cursor: pointer;
     color: #8fa6ae;
}
.filt-box.active{
    color: #153E4D;
    font-weight: 500;
}
.activity-box{
    width: 100%;
    height: 4rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    font-size: .9rem;
}
.activity-box.update{
    border-left: 3px solid #f340b7;
}
.activity-box.added{
    border-left: 3px solid #40f355;
}
.activity-box.repair{
    border-left: 3px solid #039ab8;
}
.act-box{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
}
@media only screen and (max-width: 720px) {
    .section__container{
        width: 100%;
        padding: 20px 1rem;
    }
    .product-top, .product-btm{
        flex-wrap: wrap;
        gap: .5rem;
    }
    .product-top{
        flex-wrap: wrap-reverse;
    }
    .product-btm{
        padding: 0;
    }
    .circle{
        width: 2rem;
        height: 2rem;
        font-size: .9rem;
    }
    .filter-box{
        flex-wrap: wrap;
    }
    .p-name{
        font-size: 1.2rem;
    }
}