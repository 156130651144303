.wrapper .sidebar{
    background: #6d40f3;
    position: fixed;
    top: 0;
    left: 0;
    width: 15%;
    height: 100%;
    padding: 20px 0;
    transition: all 0.2s ease;
    z-index: 999;
}
.sidebar{
    overflow: hidden;
}

.wrapper .sidebar ul{
    padding-left: 0px;
}

.wrapper .sidebar ul li{
    display: block;
    padding: 13px 30px;
    color: #0c7db1;
    font-size: 16px;
    position: relative;
}

.wrapper .sidebar ul li:hover{
    color: #0c7db1;
    background:#F6C504;
    border-right: 2px solid rgb(5, 68, 104);
    transition: all 0.5s ease;
}

.link{
    text-decoration: none;
    color: white;
    padding: .8rem 1rem;
}
.link.active,.link:hover{
    color: #6d40f3;
    background-color: white;
    border-radius: 8px;
    
}

.sides{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
}
.menu{
    display: flex;
    align-items: center;
    gap:.5rem
}
.logo{
    width: auto;
    height: 4rem;
    padding: 1rem 1rem;
}
.logo img{
    width: auto;
    height: 100%;
}
.ham{
    position: absolute;
    background-color: white;
    color: #6d40f3;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    top: 2%;
    right: 3%;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: all .5s ease;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}

@media only screen and (max-width: 720px) {
    .ham{
        display: flex;
    }
    .wrapper .sidebar{
        width: 0%;
    }
    .wrapper .sidebar.active{
        width: 73%;
    }
    
}