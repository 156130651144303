.people__row_style{
    margin-bottom: 20px;
}
.top-style{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;
}
.search-box{
    width: 30%;
}
.action-btn{
    height: 3rem;
    background-color: #6d40f3;
    color: white;
    border-radius: 6px;
    border: 0;
    width: fit-content;
    padding: 0 2rem;
}
.action-btn.blank{
    background-color: transparent;
    color: #153E4D;
    padding: 0 1rem;
    border: 1px solid #153E4D;
    border-radius: 0;
}
.action-btn.blank span{
    padding-left: .5rem;
}
.table-btn{
    height: 2rem;
    border-radius: 4px;
    width: fit-content;
    padding: 0 1rem;
    font-size: .81rem;
    transition: all .3s ease;
    background-color: transparent;
    border: 1px solid #6d40f3;
    color: #6d40f3;
    white-space: nowrap;
}
.table-btn:hover{
    background-color: #6d40f3;
    color: white;
}
.page-count{
    font-size: .9rem;
}
.view-more{
    height: 2.5rem;
    border-radius: 6px;
    width: fit-content;
    padding: 0 2rem;
    background-color: #6d40f3;
    border: 0;
    color: white;
}
.labels{
    font-size: .8rem;
}
.form-controls{
    border-radius: 4px;
    height: 3rem;
    border: 1px solid #eee;
    width: 100%;
    padding: 0 1rem;
    font-size: .8rem;
    outline: 0
}
.form-controls.text-area{
    resize: none;
    height: 5rem;
    padding: 1rem;
}
.input-form{
    display: flex;
    flex-direction: column;
    gap:0rem
}
.btn-form{
    width: 100%;
    height: 3rem;
    border: 0;
    border-radius: 6px;
    background-color: transparent;
    color: #6d40f3;
    
}
.btn-form.outline{
    border: 1px solid #6d40f3;
}
.btn-form.fill{
    background-color:  #6d40f3;
    color: white;
}
.modal-foot{
    margin: 0rem 0 2rem;
    padding: 1rem 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-top: 1px solid #eee;
}

@media only screen and (max-width: 720px) {
    .top-style{
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
    }
    .search-box{
        width: 100%;
    }
    .page-count{
        display: none;
    }
}